<template>
  <div :class="col">

    <label class="fs-6 fw-bold mb-2">{{title}}</label>
    <div class="col-lg-8">
      <div class="image-input image-input-outline" data-kt-image-input="true"
           :style="`background-image: url(${element && element.url ? element.url : element})`">
        <!--begin::Preview existing avatar-->
        <div class="image-input-wrapper w-125px h-125px"
             :style="`background-image: url(${element && element.url ? element.url : element})`"></div>
        <!--end::Preview existing avatar-->

        <!--begin::Label-->
        <label
            class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
            data-kt-image-input-action="change"
            data-bs-toggle="tooltip"
            title="Change avatar">
          <i class="bi bi-pencil-fill fs-7"></i>

          <!--begin::Inputs-->
          <input type="file" ref="profile" name="profile" @change="handleImageChange($event,imageEle)"/>
          <input type="hidden" name="avatar_remove"/>
          <!--end::Inputs-->
        </label>
        <!--end::Label-->

        <!--begin::Remove-->
        <span
            class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
            data-kt-image-input-action="remove"
            data-bs-toggle="tooltip"
            @click="handleImageRemove(imageEle)"
            title="Remove profile image"><i class="bi bi-x fs-2"></i></span>
        <!--end::Remove-->
      </div>
      <!--end::Image input-->
    </div>
  </div>
</template>

<script>

import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "image-comp",
  emit : ["update:modelValue"],
  props: ['element' , 'col' , 'title'],
  setup(props,{emit}) {

    const imageEle = ref(null);
    const handleImageChange = (e,item) => {
      var image = e.target.files || e.dataTransfer.files;
      var file = e.target.files[0];
      if (!image.length)
        return;
      var reader = new FileReader();
      reader.onload = event => {
        file.url =  event.target? event.target.result : '';
        imageEle.value = file;
      }
      reader.readAsDataURL(image[0])

    }

    const handleImageRemove = (item,file) => {
      imageEle.value = null;
    }
    watch(imageEle, (currentValue, oldValue) => {
      emit('update:modelValue' , currentValue);
    });

    return {
      // element,
      imageEle,
      handleImageChange,
      handleImageRemove
    };
  }

});
</script>
