<template>
 <div>
   <div class="card mb-5 mb-xl-10">
     <!--begin::Card header-->
     <div
         class="card-header border-0 cursor-pointer"
         role="button"
         data-bs-toggle="collapse"
         data-bs-target="#kt_account_profile_details"
         aria-expanded="true"
         aria-controls="kt_account_profile_details"
     >
       <!--begin::Card title-->
       <div class="card-title m-0">
         <h3 class="fw-bolder m-0">Profile Details</h3>
       </div>
       <!--end::Card title-->
     </div>
     <!--begin::Card header-->

     <!--begin::Content-->
     <div id="kt_account_profile_details" class="collapse show">
       <el-form
           v-loading="loadingData"
           :model="objData"
           :rules="rules"
           ref="formRef"
       >
         <div class="card mb-5 mb-xl-10">
           <div class="card-body pt-9 pb-0">
             <div class="row g-9 mb-7">

               <div class="col-md-4 fv-row">
                 <label class="required fs-6 fw-bold mb-2">Name</label>
                 <el-form-item prop="name" :rules="rules['field']">
                   <el-input v-model="objData.name" placeholder="Name"></el-input>
                 </el-form-item>
               </div>
               <div class="col-md-4 fv-row">
                 <label class="required fs-6 fw-bold mb-2">Email</label>
                 <el-form-item prop="email" :rules="rules['field']">
                   <el-input v-model="objData.email" placeholder="Email"></el-input>
                 </el-form-item>
               </div>
               <image-comp :element="objData.image" v-model="objData.image" col="col-md-3 fv-row" title="Image"></image-comp>
             </div>
           </div>
         </div>

         <div class="row g-9 mb-7">
           <div class="col-md-4 fv-row">

           </div>
           <div class="col-md-4 fv-row">
             <button
                 @click="updateProfile()"
                 :data-kt-indicator="loading ? 'on' : null"
                 class="btn btn-lg btn-primary full-width"
                 type="button"
                 :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Save
              </span>
               <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
             </button>
           </div>
           <div class="col-md-4 fv-row">

           </div>
         </div>

       </el-form>
     </div>
     <!--end::Content-->
   </div>
   <div class="card mb-5 mb-xl-10">
     <!--begin::Card header-->
     <div
         class="card-header border-0 cursor-pointer"
         role="button"
         data-bs-toggle="collapse"
         data-bs-target="#kt_account_profile_password"
         aria-expanded="true"
         aria-controls="kt_account_profile_password"
     >
       <!--begin::Card title-->
       <div class="card-title m-0">
         <h3 class="fw-bolder m-0">Change Password</h3>
       </div>
       <!--end::Card title-->
     </div>
     <!--begin::Card header-->

     <!--begin::Content-->
     <div id="kt_account_profile_password" class="collapse show">
       <el-form
           :model="objPasswordData"
           :rules="rules"
           ref="formPasswordRef"
       >
         <div class="card mb-5 mb-xl-10">
           <div class="card-body pt-9 pb-0">
             <div class="row g-9 mb-7">

               <div class="col-md-4 fv-row">
                 <label class="required fs-6 fw-bold mb-2">Current Password</label>
                 <el-form-item prop="current_password" :rules="rules['field']">
                   <el-input type="password" v-model="objPasswordData.current_password" placeholder="Current Password"></el-input>
                 </el-form-item>
               </div>
               <div class="col-md-4 fv-row">
                 <label class="required fs-6 fw-bold mb-2">New Password</label>
                 <el-form-item prop="new_password" :rules="rules['field']">
                   <el-input type="password" v-model="objPasswordData.new_password" placeholder="New Password"></el-input>
                 </el-form-item>
               </div>
               <div class="col-md-4 fv-row">
                 <label class="required fs-6 fw-bold mb-2">New Password Confirmation</label>
                 <el-form-item prop="new_password_confirmation" :rules="rules['field']">
                   <el-input type="password" v-model="objPasswordData.new_password_confirmation" placeholder="New Password Confirmation"></el-input>
                 </el-form-item>
               </div>
             </div>
           </div>
         </div>

         <div class="row g-9 mb-7">
           <div class="col-md-4 fv-row">

           </div>
           <div class="col-md-4 fv-row">
             <button
                 @click="changePassword()"
                 :data-kt-indicator="passwordLoading ? 'on' : null"
                 class="btn btn-lg btn-primary full-width"
                 type="button"
                 :disabled="passwordLoading">
              <span v-if="!passwordLoading" class="indicator-label">
                Save
              </span>
               <span v-if="passwordLoading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
             </button>
           </div>
           <div class="col-md-4 fv-row">

           </div>
         </div>

       </el-form>
     </div>
     <!--end::Content-->
   </div>
 </div>
</template>

<script>
import {defineComponent, onMounted, ref , inject} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useRoute} from "vue-router";
import ImageComp from "@/components/admin/general/ImageComp";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {APIs} from "@/store/enums/ApiEnums";
import {handleError, handleSuccess} from "@/core/helpers/response";
import {Mutations} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "admin-profile",
  components: {ImageComp},

  setup() {
    const emitter = inject('emitter');
    const store = useStore();
    const formRef = ref(null);
    const formPasswordRef = ref(null);
    const objData = ref({});
    const objPasswordData = ref({
      current_password : '',
      new_password : '',
      new_password_confirmation : '',
    });
    const loading = ref(null);
    const passwordLoading = ref(null);
    const loadingData = ref(null);
    const route = useRoute();

    objData.value = store.getters.currentUser;
    onMounted(() => {
      setCurrentPageBreadcrumbs("My Profile", []);
    });
    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });

    const updateProfile = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          var formData = new FormData();
          Object.keys(objData.value).forEach(function (key) {
            formData.append(key, objData.value[key]);
          });
          loading.value = true;
          ApiAxiosService.post(APIs.PROFILE.profile, formData).then(function (res) {
            loading.value = null;
            store.commit(Mutations.SET_AUTH , res.data.data);
            emitter.emit('profile_updated');
            handleSuccess(res);
          }).catch(function (err) {
            loading.value = null;
            handleError(err);
          });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const changePassword = () => {
      formPasswordRef.value.validate((valid) => {
        if (valid) {
          var formData = new FormData();
          Object.keys(objPasswordData.value).forEach(function (key) {
            formData.append(key, objPasswordData.value[key]);
          });
          passwordLoading.value = true;
          ApiAxiosService.post(APIs.PROFILE.change_password, formData).then(function (res) {
            passwordLoading.value = null;
            handleSuccess(res);
          }).catch(function (err) {
            passwordLoading.value = null;
            handleError(err);
          });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }

    return {
      formRef,
      formPasswordRef,
      objData,
      objPasswordData,
      loading,
      passwordLoading,
      loadingData,
      rules,

      updateProfile,
      changePassword,
      getIllustrationsPath,
    };
  },

});
</script>
